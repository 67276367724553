"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedeemNftRewardBodyToJSON = exports.RedeemNftRewardBodyFromJSONTyped = exports.RedeemNftRewardBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function RedeemNftRewardBodyFromJSON(json) {
    return RedeemNftRewardBodyFromJSONTyped(json, false);
}
exports.RedeemNftRewardBodyFromJSON = RedeemNftRewardBodyFromJSON;
function RedeemNftRewardBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transactionHash': json['transactionHash'],
        'nftRewardMetadataId': json['nftRewardMetadataId'],
        'nftOwnershipId': !(0, runtime_1.exists)(json, 'nftOwnershipId') ? undefined : json['nftOwnershipId'],
    };
}
exports.RedeemNftRewardBodyFromJSONTyped = RedeemNftRewardBodyFromJSONTyped;
function RedeemNftRewardBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transactionHash': value.transactionHash,
        'nftRewardMetadataId': value.nftRewardMetadataId,
        'nftOwnershipId': value.nftOwnershipId,
    };
}
exports.RedeemNftRewardBodyToJSON = RedeemNftRewardBodyToJSON;
